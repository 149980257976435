import { memo } from "react";
export type Horizontal = "left" | "right";
export type Vertical = "top" | "bottom";
type CirclesProps = {
  amount: number;
  fade?: "in" | "out";
  split?: Vertical | Horizontal | `${Vertical} ${Horizontal}`;
  opacity?: number;
};

const Circles: React.FC<React.SVGAttributes<SVGElement> & CirclesProps> = memo(
  ({ amount, fade, split, opacity, ...props }) => {
    const getRadius = (val: number) => {
      return 5 + val * (10 + val / 2);
    };
    const getOpacity = (pos: number) => {
      const t = pos / amount;
      const lerp = (a: number, b: number, t: number) => a * (1 - t) + b * t;
      if (fade === "in") return lerp(0, opacity ?? 1, t);
      if (fade === "out") return lerp(opacity ?? 1, 0, t);
      return 1;
    };
    const getViewBox = () => {
      const startPoint = [-totalWidth, -totalWidth];
      const endPoint = [totalWidth * 2, totalWidth * 2];
      if (!split) return `${startPoint.join(" ")} ${endPoint.join(" ")}`;
      let horizontal: Horizontal | undefined, vertical: Vertical | undefined;
      if (split.includes(" ")) {
        [vertical, horizontal] = split.split(" ") as [Vertical, Horizontal];
      } else {
        if (split === "top" || split === "bottom") {
          vertical = split;
        } else if (split === "left" || split === "right") {
          horizontal = split;
        } else {
          throw new Error("Invalid split value");
        }
      }
      if (horizontal === "left") {
        endPoint[0] = totalWidth;
      } else if (horizontal === "right") {
        startPoint[0] = 0;
        endPoint[0] = totalWidth;
      }
      if (vertical === "top") {
        endPoint[1] = totalWidth;
      } else if (vertical === "bottom") {
        startPoint[1] = 0;
        endPoint[1] = totalWidth;
      }
      return `${startPoint.join(" ")} ${endPoint.join(" ")}`;
    };
    const totalWidth = getRadius(amount);
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={getViewBox()} {...props}>
        <g
          fill="none"
          stroke="inherit"
          strokeLinecap="round"
          className="animate-spin-slower"
        >
          {Array.from({ length: amount }).map((_, i) => {
            const isEven = i % 2 === 0;
            const base = Math.trunc(
              (Math.PI * getRadius(i) * 2) / (isEven ? 25 : 45),
            );
            const dashArray = [base, Math.trunc(base / 3)];
            return (
              <circle
                key={i}
                cx={0}
                cy={0}
                r={getRadius(i)}
                strokeDasharray={dashArray.join(" ")}
                strokeDashoffset="5"
                strokeWidth={1 + i / 10}
                opacity={getOpacity(i)}
                transform={`rotate(${Math.random() * 360})`}
                // className={isEven ? "animate-spin" : ""}
              />
            );
          })}
        </g>
      </svg>
    );
  },
);
Circles.displayName = "Circles";

export default Circles;
