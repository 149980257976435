import { useSession } from "next-auth/react";
import { useRef, useState } from "react";
import { toast } from "sonner";
import { z } from "zod";

import {
  LoadingModal,
  type LoadingModalHandle,
} from "@components/LoadingModal";

import { TRPCClientError } from "@trpc/client";
import { trpc } from "@utils/trpc";
import { useTranslations } from "next-intl";

const emailSchema = z.string().email();

export const Subscribe = () => {
  const t = useTranslations("Ticketing.components.MailingListSubscribe");
  const loaderRef = useRef<LoadingModalHandle>(null);
  const [email, setEmail] = useState("");
  const { data } = useSession();
  const { mutateAsync, isLoading } = trpc.mailingList.create.useMutation();

  const subscribe = async () => {
    try {
      const { success } = emailSchema.safeParse(email);

      if (!success) {
        return toast.error(t("invalidEmail"));
      }

      await mutateAsync({
        email,
        userAddress: data?.address,
      });

      toast.success(t("success"));
      setEmail("");
    } catch (error) {
      if (error instanceof TRPCClientError) {
        return toast.error(error.message);
      }

      toast.error(t("error"));
    }
  };

  return (
    <>
      <div className="relative">
        <input
          type="email"
          placeholder={t("placeholder")}
          className="w-full rounded-full border border-stone-100 px-4 py-3 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder-white"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={isLoading}
        />

        <button
          type="button"
          disabled={isLoading}
          className="absolute right-2 top-[9px] h-8 rounded-full bg-accent px-6 py-2 font-display text-sm text-white transition-colors hover:bg-accent-dark disabled:bg-accent-dark"
          onClick={subscribe}
        >
          {isLoading ? (
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            t("subscribe")
          )}
        </button>
      </div>
      <LoadingModal ref={loaderRef} />
    </>
  );
};
