import Image from "next/image";
import ExploreImage from "@public/landing-explore.jpg";
import EngageImage from "@public/landing-engage.jpg";
import PromoteImage from "@public/landing-promote.jpg";
import { SpiralMasked } from "@components/Spiral";
import TicketingBackdrop from "@components/TicketingBackdrop";
import { TicketingFooter } from "@components/TicketingFooter";
import { TicketingHeader } from "@components/TicketingHeader";
import { Button } from "@components/ui/Button";
import { localeValidator } from "common";
import { type GetStaticProps } from "next";
import { useTranslations } from "next-intl";
import {
  Atom,
  BarChart2,
  Banknote,
  ExternalLink,
  Fingerprint,
  Hammer,
  Medal,
  PartyPopper,
  Radar,
  Blocks,
} from "lucide-react";
import Link from "next/link";
import { getTicketingHref } from "@components/TicketingLink";
import { Subscribe } from "@components/Subscribe";

export const getStaticProps: GetStaticProps = async (ctx) => {
  return {
    props: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      messages: (
        await import(
          `common/messages/${localeValidator.parse(ctx.locale)}.json`
        )
      ).default,
    },
    revalidate: 60,
  };
};

export default function TicketingPage() {
  const t = useTranslations("Ticketing.pages.index");
  const term = useTranslations("Terms");
  return (
    <>
      <TicketingBackdrop as="div">
        <TicketingHeader />
        <main className="relative m-2 flex flex-col items-center rounded-md bg-card p-3 pb-7 ring-2 ring-ring">
          <section className="mt-20 flex flex-col items-center space-y-9">
            <h1 className="flex select-none flex-col items-start justify-center font-display text-6xl text-card-foreground md:flex-row md:space-x-3 lg:text-7xl xl:text-[5.5rem]">
              {t.rich("verbs", {
                first: (word) => (
                  <span className="relative pb-1 children:block">
                    <span className="relative z-0 animate-explore-gradient-background">
                      {word}
                    </span>
                    <span
                      className="absolute bottom-0 left-0 top-0 z-10 animate-explore-gradient bg-gradient-to-r from-explore-start to-explore-end bg-clip-text text-transparent"
                      style={{
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {word}
                    </span>
                  </span>
                ),
                second: (word) => (
                  <span className="relative pb-1 children:block">
                    <span className="relative z-0 animate-participate-gradient-background">
                      {word}
                    </span>
                    <span
                      className="absolute bottom-0 left-0 top-0 z-10 animate-participate-gradient bg-gradient-to-r from-participate-start to-participate-end bg-clip-text text-transparent"
                      style={{
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {word}
                    </span>
                  </span>
                ),
                third: (word) => (
                  <span className="relative pb-1 children:block">
                    <span className="relative z-0 animate-promote-gradient-background">
                      {word}
                    </span>
                    <span
                      className="absolute bottom-0 left-0 top-0 z-10 animate-promote-gradient bg-gradient-to-r from-promote-start to-promote-end bg-clip-text text-transparent"
                      style={{
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {word}
                    </span>
                  </span>
                ),
              })}
            </h1>
            <p className="max-w-[80%] text-center text-md text-card-foreground opacity-70 md:text-lg">
              {t("verbsDescription")}
            </p>
            <div className="flex flex-col-reverse justify-center gap-y-2 children:text-lg md:block md:space-x-2">
              <Button variant={"secondary"} asChild>
                <Link
                  href="https://www.spatial.io/s/Open-Ticket-Spaceship-64ba554ce20b6d94c1df250b?share=6713915543840410238"
                  target="_blank"
                >
                  {t("headerSecondCta")}
                </Link>
              </Button>
              <Button asChild>
                <Link href={getTicketingHref(`/events`)}>
                  {t("headerFirstCta")}
                </Link>
              </Button>
            </div>
          </section>
          <section className="mt-32 flex w-full flex-col items-center">
            <small className="text-sm font-medium uppercase tracking-widest text-card-foreground opacity-70">
              {t("explore")}
            </small>
            <div className="mt-20">
              <div className="flex flex-col items-center">
                <div className="relative flex justify-center">
                  <SpiralMasked className="w-20 bg-gradient-to-r from-explore-start to-explore-end"></SpiralMasked>
                  <div className="absolute bottom-4 mx-auto font-display text-lg text-card">
                    1
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-4">
                  <h2 className="mt-4 bg-gradient-to-r from-explore-start to-explore-end bg-clip-text text-center font-display text-2xl tracking-wide text-transparent">
                    {t("firstVerb.verb")}
                  </h2>
                  <h3 className="text-center font-display text-2xl md:text-6xl">
                    {t("firstVerb.title")}
                  </h3>
                  <p className="mx-auto max-w-[80%] text-center text-card-foreground opacity-80">
                    {t("firstVerb.description")}
                  </p>
                </div>
              </div>
              <div className="container mx-auto mt-12 flex flex-col items-center gap-6 md:flex-row md:items-start">
                <div className="relative aspect-square w-full grow overflow-hidden rounded-md ring-2 ring-ring">
                  <Image
                    src={ExploreImage}
                    alt="Explore"
                    fill
                    priority
                    quality={100}
                    className="h-auto w-full object-cover object-left"
                  />
                </div>
                <div className="flex flex-col space-y-8">
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <Hammer size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("firstVerb.first.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("firstVerb.first.description")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <PartyPopper size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("firstVerb.second.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("firstVerb.second.description")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <Atom size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("firstVerb.third.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("firstVerb.third.description")}
                    </p>
                  </div>
                  <Link
                    href={
                      "https://www.spatial.io/s/Open-Ticket-Spaceship-64ba554ce20b6d94c1df250b?share=6713915543840410238"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="mx-auto flex w-max items-center rounded-full border border-ring bg-content px-3 py-2 md:mx-0">
                      <div className="mr-2 rounded-full bg-explore-start px-2">
                        {term("new")}
                      </div>
                      <span className="mr-1 text-card-foreground opacity-80">
                        {t("visitTheMetaverse")}
                      </span>
                      <ExternalLink size={16} className="mb-[1px]" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="border-boder my-20 w-full border-b border-t py-10 text-center font-display text-xl">
            Parceiros ou algo do tipo
          </section> */}
          <section className="mt-36">
            <div className="flex flex-col items-center">
              <div className="relative flex justify-center">
                <SpiralMasked
                  className="w-20 bg-gradient-to-r from-participate-end to-participate-start"
                  style={{
                    transform: "scaleX(-1)",
                  }}
                ></SpiralMasked>
                <div className="absolute bottom-4 mx-auto font-display text-lg text-card">
                  2
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4">
                <h2 className="mt-4 bg-gradient-to-r from-participate-start to-participate-end bg-clip-text text-center font-display text-2xl tracking-wide text-transparent">
                  {t("secondVerb.verb")}
                </h2>
                <h3 className="text-center font-display text-2xl md:text-6xl">
                  {t("secondVerb.title")}
                </h3>
                <p className="mx-auto max-w-[80%] text-center text-card-foreground opacity-80">
                  {t("secondVerb.description")}
                </p>
              </div>
            </div>

            <div className="container mx-auto mt-12 flex w-full flex-col items-center gap-6 md:flex-row md:items-start">
              <div className="relative aspect-square w-full grow overflow-hidden rounded-md ring-2 ring-ring">
                <Image
                  src={EngageImage}
                  alt="Explore"
                  fill
                  priority
                  quality={100}
                  className="h-auto w-full object-cover object-center"
                />
              </div>
              <div className="flex grow flex-col space-y-8">
                <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                  <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                    <Radar size={24} className="stroke-zinc-400" />
                  </div>
                  <h4 className="font-display text-lg">
                    {t("secondVerb.first.title")}
                  </h4>
                  <p className="opacity-70">
                    {t("secondVerb.first.description")}
                  </p>
                </div>
                <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                  <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                    <Medal size={24} className="stroke-zinc-400" />
                  </div>
                  <h4 className="font-display text-lg">
                    {t("secondVerb.second.title")}
                  </h4>
                  <p className="opacity-70">
                    {t("secondVerb.second.description")}
                  </p>
                </div>
                <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                  <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                    <Fingerprint size={24} className="stroke-zinc-400" />
                  </div>
                  <h4 className="font-display text-lg">
                    {t("secondVerb.third.title")}
                  </h4>
                  <p className="opacity-70">
                    {t("secondVerb.third.description")}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-32">
            <div>
              <div className="flex flex-col items-center">
                <div className="relative flex justify-center">
                  <SpiralMasked className="w-20 bg-gradient-to-r from-promote-start to-promote-end"></SpiralMasked>
                  <div className="absolute bottom-4 mx-auto font-display text-lg text-card">
                    3
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-4">
                  <h2 className="mt-4 bg-gradient-to-r from-promote-start to-promote-end bg-clip-text text-center font-display text-2xl tracking-wide text-transparent">
                    {t("thirdVerb.verb")}
                  </h2>
                  <h3 className="text-center font-display text-2xl md:text-6xl">
                    {t("thirdVerb.title")}
                  </h3>
                  <p className="mx-auto max-w-[80%] text-center text-card-foreground opacity-80">
                    {t("thirdVerb.description")}
                  </p>
                </div>
              </div>
              <div className="container mx-auto mt-12 flex flex-col items-center gap-6 md:flex-row md:items-start">
                <div className="relative aspect-square w-full grow overflow-hidden rounded-md ring-2 ring-ring">
                  <Image
                    src={PromoteImage}
                    alt="Explore"
                    fill
                    priority
                    quality={100}
                    className="h-auto w-full object-cover object-left"
                  />
                </div>
                <div className="flex flex-col space-y-8">
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <Blocks size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("thirdVerb.first.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("thirdVerb.first.description")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <BarChart2 size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("thirdVerb.second.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("thirdVerb.second.description")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center space-y-3 text-center md:items-start md:text-left">
                    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-content ring-2 ring-ring">
                      <Banknote size={24} className="stroke-zinc-400" />
                    </div>
                    <h4 className="font-display text-lg">
                      {t("thirdVerb.third.title")}
                    </h4>
                    <p className="opacity-70">
                      {t("thirdVerb.third.description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="relative my-20 w-full py-10">
            <div className="absolute inset-0 flex items-center justify-center rounded-md bg-content">
              Stats? (events, tickets sold, something)
            </div>
          </section> */}
          <section className="mt-32">
            <div className="w-full space-y-5">
              <h2 className="text-center font-display text-2xl">
                {t("mailingList.title")}
              </h2>
              <p className="text-center text-card-foreground opacity-70">
                {t("mailingList.description")}
              </p>
              <div className="mx-auto w-80">
                <Subscribe />
              </div>
            </div>
          </section>
        </main>
        <TicketingFooter />
      </TicketingBackdrop>
    </>
  );
}
