import { cn } from "@lib/utils";
import * as React from "react";
const SvgComponent = ({ ...props }: React.ComponentProps<"svg">) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 520" {...props}>
      <path
        fill="none"
        stroke="#ff3895"
        strokeWidth={7}
        d="M117.945 35.947a2.31 2.31 0 0 1-2.058 1.048l-14.634-.766a2.31 2.31 0 0 1-1.937-1.258l-6.652-13.057a2.31 2.31 0 0 1 .12-2.306l7.982-12.29a2.31 2.31 0 0 1 2.057-1.048l14.634.767a2.31 2.31 0 0 1 1.937 1.257l6.653 13.057a2.31 2.31 0 0 1-.121 2.306z"
        opacity={0.13}
      />
      <path
        fill="none"
        stroke="#f349a4"
        strokeWidth={8}
        d="M103.536 78.828a3.045 3.045 0 0 1-2.421 1.846L81.952 83.14a3.045 3.045 0 0 1-2.81-1.173L67.425 66.604a3.045 3.045 0 0 1-.39-3.02l7.447-17.829a3.045 3.045 0 0 1 2.42-1.846l19.164-2.467a3.045 3.045 0 0 1 2.81 1.174l11.717 15.362c.66.865.808 2.017.39 3.02z"
        opacity={0.227}
      />
      <path
        fill="none"
        stroke="#e657b2"
        strokeWidth={9}
        d="M94.36 122.147a3.78 3.78 0 0 1-2.546 2.795l-22.857 7.28a3.78 3.78 0 0 1-3.693-.807L47.53 115.26a3.78 3.78 0 0 1-1.147-3.603l5.124-23.435a3.78 3.78 0 0 1 2.546-2.795l22.857-7.28a3.78 3.78 0 0 1 3.694.808L98.337 95.11a3.78 3.78 0 0 1 1.148 3.602z"
        opacity={0.323}
      />
      <path
        fill="none"
        stroke="#d962bf"
        strokeWidth={10}
        d="M90.195 165.467a4.516 4.516 0 0 1-2.393 3.83L62.5 182.75a4.516 4.516 0 0 1-4.514-.157l-24.302-15.186a4.517 4.517 0 0 1-2.12-3.987l1-28.64a4.516 4.516 0 0 1 2.394-3.829l25.302-13.453a4.516 4.516 0 0 1 4.513.157l24.302 15.186a4.517 4.517 0 0 1 2.12 3.987z"
        opacity={0.42}
      />
      <path
        fill="none"
        stroke="#cb6dcb"
        strokeWidth={11}
        d="M90.837 208.426a5.252 5.252 0 0 1-1.94 4.88l-26.139 20.669a5.251 5.251 0 0 1-5.196.761l-30.97-12.303a5.252 5.252 0 0 1-3.257-4.12l-4.83-32.972a5.252 5.252 0 0 1 1.94-4.88l26.14-20.67a5.251 5.251 0 0 1 5.195-.76l30.97 12.303a5.252 5.252 0 0 1 3.257 4.12z"
        opacity={0.517}
      />
      <path
        fill="none"
        stroke="#bc77d6"
        strokeWidth={12}
        d="M96.12 250.716a5.988 5.988 0 0 1-1.178 5.87l-25.09 28.528a5.987 5.987 0 0 1-5.673 1.916l-37.25-7.466a5.988 5.988 0 0 1-4.496-3.954l-12.16-35.993a5.988 5.988 0 0 1 1.177-5.87l25.091-28.528a5.987 5.987 0 0 1 5.672-1.916l37.25 7.466a5.988 5.988 0 0 1 4.497 3.954z"
        opacity={0.613}
      />
      <path
        fill="none"
        stroke="#ab7fe1"
        strokeWidth={13}
        d="M105.923 292.063a6.723 6.723 0 0 1-.118 6.722L83.834 335.35a6.723 6.723 0 0 1-5.88 3.26l-42.652-.745a6.723 6.723 0 0 1-5.763-3.463l-20.682-37.31a6.723 6.723 0 0 1 .118-6.722l21.971-36.566a6.723 6.723 0 0 1 5.88-3.26l42.652.745a6.723 6.723 0 0 1 5.763 3.463z"
        opacity={0.71}
      />
      <path
        fill="none"
        stroke="#9988ec"
        strokeWidth={14}
        d="M120.176 332.194a7.459 7.459 0 0 1 1.21 7.36l-16.703 44.28a7.458 7.458 0 0 1-5.77 4.728l-46.7 7.675a7.46 7.46 0 0 1-6.978-2.632L15.238 357a7.459 7.459 0 0 1-1.209-7.36l16.703-44.28a7.458 7.458 0 0 1 5.769-4.728l46.7-7.675a7.46 7.46 0 0 1 6.978 2.632z"
        opacity={0.807}
      />
      <path
        fill="none"
        stroke="#8490f5"
        strokeWidth={15}
        d="M138.853 370.814a8.194 8.194 0 0 1 2.757 7.716l-9.326 51.15a8.194 8.194 0 0 1-5.303 6.247L78.02 453.425a8.195 8.195 0 0 1-8.062-1.47l-39.634-33.652a8.194 8.194 0 0 1-2.758-7.716l9.327-51.15a8.194 8.194 0 0 1 5.303-6.247l48.961-17.498a8.195 8.195 0 0 1 8.061 1.47z"
        opacity={0.903}
      />
      <path
        fill="#6b97ff"
        stroke="#6b97ff"
        strokeWidth={16}
        d="M162.034 407.698a8.93 8.93 0 0 1 4.447 7.744l-.122 56.661a8.93 8.93 0 0 1-4.481 7.724l-49.132 28.224a8.93 8.93 0 0 1-8.93-.019l-49.008-28.436a8.93 8.93 0 0 1-4.448-7.744l.122-56.661a8.93 8.93 0 0 1 4.481-7.724l49.132-28.224a8.93 8.93 0 0 1 8.93.019z"
      />
    </svg>
  );
};
export default SvgComponent;

export const SpiralMasked = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>((props, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(props.className, "aspect-[216/520] h-auto")}
      style={{
        ...props.style,
        WebkitMaskImage: "url(/spiral-mask-image.png)",
        WebkitMaskSize: "contain",
        WebkitMaskRepeat: "no-repeat",
        maskImage: "url(/spiral-mask-image.png)",
        maskRepeat: "no-repeat",
        maskSize: "contain",
      }}
    />
  );
});

SpiralMasked.displayName = "SpiralMasked";
