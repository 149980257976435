import { Dialog } from "@headlessui/react";
import { useBeforeUnload } from "react-use";
import cs from "classnames";
import React, { useImperativeHandle, useState } from "react";
import { Button } from "./Button";

export type ExtraOptions = {
  title: string;
  variant?: React.ComponentProps<typeof Button>["variant"];
  onClick: (
    ref: React.MutableRefObject<LoadingModalHandle | null>,
  ) => void | Promise<void>;
};

export type LoadingModalState = {
  id: string | number;
  extra?: ExtraOptions[];
  title?: string;
  message: string;
  subtitle?: React.ReactNode;
  final?: boolean;
};

export type LoadingModalHandle = {
  push: (state: LoadingModalState) => void;
  reset: () => void;
};

type LoadingModalProps = {
  open?: boolean;
  onRequestClose?: () => boolean;
};

export const LoadingModal = React.forwardRef<
  LoadingModalHandle,
  LoadingModalProps
>((props, ref) => {
  const {
    open,
    onRequestClose = () => {
      return true;
    },
  } = props;
  const [stateArray, setStateArray] = useState<Array<LoadingModalState>>([]);
  const reset = () => {
    if (onRequestClose()) {
      setStateArray([]);
    }
  };
  useImperativeHandle(ref, () => ({
    push: (state) => {
      setStateArray((prev) => [
        { ...state, id: makeLoaderId(state.id) },
        ...prev,
      ]);
    },
    reset,
  }));
  const canClose = stateArray[0] ? stateArray[0].final ?? false : true;
  useBeforeUnload(
    !canClose,
    "Your request is processing. Are you sure you want to leave?",
  );
  const title = stateArray[0]?.title || "Loading...";
  return (
    <Dialog
      open={open !== undefined ? open : stateArray.length > 0}
      onClose={() => onRequestClose()}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <Dialog.Panel className="modal-content min-w-full md:min-w-[34rem]">
          <Dialog.Title as="div" className="modal-header">
            <h5 className="modal-title" id="addPropertiesLabel">
              {title}
            </h5>
            {canClose && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={reset}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            )}
          </Dialog.Title>
          {/* Body */}
          <div className="modal-body relative flex h-40 flex-col-reverse overflow-hidden p-6 dark:text-gray-50">
            {/* <div className="pointer-events-none absolute  inset-0 bg-gradient-to-b from-white via-[#ffffff6e]"></div> */}
            {stateArray.map((state, i) => {
              return (
                <div
                  key={state.id}
                  className={cs("mt-3 text-lg font-light", {
                    "opacity-80": i === 1,
                    "opacity-60": i === 2,
                    "opacity-40": i === 3,
                    "opacity-20": i === 4,
                    "opacity-5": i >= 5,
                  })}
                >
                  {state.message}
                  {state.subtitle && <Subtitle>{state.subtitle}</Subtitle>}
                </div>
              );
            })}
          </div>
          {stateArray[0]?.extra && (
            <div className="modal-footer space-x-3">
              {stateArray[0].extra.map((action, i) => {
                return (
                  <Button
                    onClick={() => {
                      if (typeof ref !== "function") {
                        if (!ref?.current) return;
                        return action.onClick(ref);
                      }
                    }}
                    key={i}
                    variant={action.variant ?? "filled"}
                  >
                    {action.title}
                  </Button>
                );
              })}
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
});
LoadingModal.displayName = "LoadingModal";

export const Subtitle: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return (
    <small
      {...props}
      className={cs(
        "mt-1 block text-sm font-light text-gray-500 dark:text-gray-300",
        props.className,
      )}
    />
  );
};

export const makeLoaderId = (val: string | number) => `${val}${+Date.now()}`;
